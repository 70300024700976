import "../../home.css";
import PublicTitle from "../../../../components/PublicTitle";
import { Carousel } from "antd";
const contentStyle3 = {
  height: "250px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  width: "600px",
};
function News() {
  const onChange = (currentSlide) => {};
  return (
    <>
      <div className="new">
        <PublicTitle title1="新闻动态" title2="News Centers" />
        <div className="news_box" style={{ margin: "0 auto" }}>
          <div className="news_box_left">
            <div className="news_box_left_img">
              <Carousel afterChange={onChange} autoplay>
                <div>
                  <h3 style={contentStyle3}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="https://static.ecipc.cn/3.28_2.jpg"
                      alt=""
                    />
                  </h3>
                </div>
                <div>
                  <h3 style={contentStyle3}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="https://static.ecipc.cn/3.28_1.jpg"
                      alt=""
                    />
                  </h3>
                </div>
              </Carousel>
            </div>
            <div className="news_title">提升知识产权综合竞争力</div>
            <a
              href="http://www.xm.gov.cn/xmyw/202209/t20220910_2686740.htm"
              target="_blank"
              rel="noreferrer"
            >
              <div className="news_left_bottom">
                <div className="news_left_bottom_time">
                  <div className="year">9月</div>
                  <div className="month">2022</div>
                </div>
                <div className="new_left_bottom_content">
                  中国（厦门）电子商务知识产权运营中心9月9日在厦门国际会展中心揭牌。厦门市副市长庄荣良等为运营中心揭牌。
                </div>
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://www.cnipa.gov.cn/art/2022/9/23/art_53_178956.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="news_box_right">
                <div className="news_box_right_times">
                  <div className="year">9月</div>
                  <div className="month">2022</div>
                </div>
                <div className="news_box_right_content">
                  <div className="content_h1" style={{ color: "#666" }}>
                    知识产权报社论：凝心聚力建强国 笃行不怠向未来
                  </div>
                  <div className="content_h2">
                    全国广大知识产权工作者携手各界以昂扬向上的姿态向着知识产权强国建设的目标砥砺前行，以实际行动迎接党的二十大胜利召开。
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://www.cnipa.gov.cn/col/col3015/index.html"
              target="_blank"
              rel="noreferrer"
            >
              <div className="news_box_right">
                <div className="news_box_right_times">
                  <div className="year"> 8月</div>
                  <div className="month">2022</div>
                </div>
                <div className="news_box_right_content">
                  <div className="content_h1" style={{ color: "#666" }}>
                    “知识产权转化运用”专题新闻发布会
                  </div>
                  <div className="content_h2">
                    8月24日，国家知识产权局举行新闻发布会，发布知识产权转化运用的进展成效。
                  </div>
                </div>
              </div>
            </a>
            <a
              href="http://www.scio.gov.cn/xwfbh/xwbfbh/wqfbh/47673/48486/index.htm"
              target="_blank"
              rel="noreferrer"
            >
              <div className="news_box_right">
                <div className="news_box_right_times">
                  <div className="year">7月</div>
                  <div className="month">2022</div>
                </div>
                <div className="news_box_right_content">
                  <div className="content_h1" style={{ color: "#666" }}>
                    国新办举行上半年知识产权相关工作统计数据新闻发布会
                  </div>
                  <div className="content_h2">
                    国务院新闻办公室于2022年7月12日（星期二）下午3时举行新闻发布会，请国家知识产权局副局长胡文辉、战略规划司司长葛树、知识产权保护司司长张志成、知识产权运用促进司司长雷筱云介绍2022年上半年知识产权相关工作统计数据，并答记者问。
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
