import "./componentnanner.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ComponentBanner(props) {
  const location = useLocation();
  console.log(location);
  // const { data_banner } = props;
  return (
    <>
      <div className="com_banner">
        <div className="com_banner_item">
          <Link to="/home">
            <span className={location.pathname == "/home" ? "flage" : null}>
              首页
            </span>
          </Link>
        </div>
        <div className="com_banner_item">
          <a
            href="https://mall.ecipc.cn/cloud"
            target="_blank"
            rel="noreferrer"
          >
            知识产权数字商城
          </a>
        </div>
        <div className="com_banner_item">
          <a href="https://data.ecipc.cn/home" target="_blank" rel="noreferrer">
            大数据平台
          </a>
        </div>
        <div className="com_banner_item">
          <Link to="tservice">
            <span className={location.pathname == "/tservice" ? "flage" : null}>
              溯源服务
            </span>
          </Link>
        </div>
        <div className="com_banner_item">
          <a href="https://mall.ecipc.cn/geograph_pc" target="_blank" rel="noreferrer">
          地理标志查询
          </a>
        </div>

        <div className="com_banner_item">
          <Link to="/financial">
            <span
              className={location.pathname == "/financial" ? "flage" : null}
            >
              金融服务
            </span>
          </Link>
        </div>
        <div className="com_banner_item">
          <Link to="/aboutus">
            <span className={location.pathname == "/aboutus" ? "flage" : null}>
              关于我们
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ComponentBanner;
