import PublicTitle from "../../../../components/PublicTitle";
import "./index.css";
function VideoModule() {
  return (
    <>
      <div className="guanyu">
        <div className="video_box">
          <PublicTitle title1="关于我们" title2="About Us" />

          <div className="auto_video">
            <video
              controls="controls"
              width={1200}
              height={481}
              src="https://static.ecipc.cn/0902.m4v"
            ></video>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoModule;
