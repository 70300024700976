import "./index.less";
function Product() {
  return (
    <>
      <div style={{ height: "50px" }}></div>
      <div className="chanpin">
        <div className="chanpin_title">
          <div className="title_left">
            <span>产品与服务</span>
            <span
              style={{
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: "700",
                color: "#ccc",
              }}
            >
              Produces & solutions
            </span>
          </div>
        </div>

        <div className="chanpin_product">
          <div className="chanpin_box">
            <div className="img1">
              <img
                className="chanpin_img"
                src="https://static.ecipc.cn/3.28%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%9C%8D%E5%8A%A11.jpg"
                alt=""
              />
            </div>
            <div className="chanpin_content1">
              <div className="chanpin_name">知识产权服务</div>
              <div className="chanpin_p">
                集聚整合各类知识产权要素和资源，打通创新主体寻找知识产权多元化专业服务的
                " 最后一公里 "
              </div>
            </div>
          </div>

          <div className="chanpin_box">
            <div className="img1">
              <img
                src="https://static.ecipc.cn/3.28%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%95%B0%E5%AD%97%E5%95%86%E5%9F%8E2.jpg"
                alt=""
              />
            </div>
            <div className="chanpin_content1">
              <div className="chanpin_name">知识产权数字商城</div>
              <div className="chanpin_p">
                以优质知识产权为基本核心，以优质正品精品为载体，解决“假冒伪劣多、维权打假难、收税难、商品价格偏离价值并严重冲击实体经济”四大痛点，促成双方交易，打造成为百分之百正品电商平台。
              </div>
            </div>
          </div>

          <div className="chanpin_box">
            <div className="img1">
              <img
                src="https://static.ecipc.cn/3.28%E6%95%B0%E6%8D%AE%E5%B9%B3%E5%8F%B0.jpg"
                alt=""
              />
            </div>
            <div className="chanpin_content1">
              <div className="chanpin_name">大数据平台</div>
              <div className="chanpin_p">
                通过打通政企数据，打造可提供决策建议与形势预判的大数据平台服务。
              </div>
            </div>
          </div>

          <div className="chanpin_box">
            <div className="img1">
              <img src="https://static.ecipc.cn/3.28%E6%BA%AF%E6%BA%90.jpg" alt="" />
            </div>
            <div className="chanpin_content1">
              <div className="chanpin_name">溯源服务</div>
              <div className="chanpin_p">
                依托“臻品码”体系完成产品全链路数据采集分析，提供认证管理应用及一物一码可监测、数据可应用（统计测算知识产权产值贡献，凸显知识产权价值）等功能
              </div>
            </div>
          </div>

          <div className="chanpin_box">
            <div className="img1">
              <img src="https://static.ecipc.cn/3.28%E9%87%91%E8%9E%8D.jpg" alt="" />
            </div>
            <div className="chanpin_content1">
              <div className="chanpin_name">金融服务</div>
              <div className="chanpin_p">
                与各大银行达成战略合作，为企业提供专业金融服务，解决资金问题。
              </div>
            </div>
          </div>

          <div className="chanpin_box" style={{ border: "0px" }}>
            {/* <div className="img1">图片</div> */}
            {/* <div className="chanpin_content1">
              <div className="chanpin_name">淘宝网</div>
              <div className="chanpin_p">
                阿里巴巴创立于1999年,是中国领先的网上批发平台，覆盖普通商品
                ,服装,电子产品,原材料,工业部件,农产品和化工产品等多个行业的买家和卖家。
              </div>
              <div className="content_bottom">
                <div>
                  <span
                    style={{ verticalAlign: "middle" }}
                    className="iconfont icon-xingqiu-copy"
                  />
                  <span style={{ color: "#ccc", marginLeft: "10px" }}></span>
                </div>
                <div className="chanpin_btn">了解详情</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
