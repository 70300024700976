// import "../../home.css";
import "./index.css";
import PublicTitle from "../../../../components/PublicTitle";
function ProjectDynamics() {
  return (
    <>
      <div className="dongtai">
        <PublicTitle
          title1="项目动态"
          title2="Project Dynamics"
          title3="全部"
        />
        <div className="pmain">
          <div className="pitem">
            <img src="https://static.ecipc.cn/3.28_pd_2.jpg" alt="" />
            <div>2021年12月29日</div>
            <div>运营中心经国家知识产权局批复成立</div>
          </div>
          <div className="pitem">
            <img src="https://static.ecipc.cn/3.28_pd_1.jpg" alt="" />
            <div>2022年5月10日</div>
            <div>市场监督管理局（知识产权局）确立项目独立、加速推进</div>
          </div>
          <div className="pitem" style={{textAlign:'center'}}>
            <img style={{width:'300px',height:'300px'}} src="https://static.ecipc.cn/4.7mhimg.png" alt="" />
            <div style={{marginTop:'60px'}}>2023年1月4日</div>
            <div>知识产权数字商城网页端&小程序正式上线运营</div>
          </div>
        </div>

        {/* <div className="team_box">
          <div className="team_box_main" style={{ flexWrap: "wrap" }}>
            <div
              className="team_box_cord"
              style={{ border: "1px solid #ccc", marginBottom: "24px" }}
            >
              <div className="team_box_cord_img">图片</div>
              <div className="team_box1">
                <span className="team_cord_name">马云</span>
                <span className="team_cord_zhi">董事局主席</span>
              </div>
              <div className="team_cord_content">
                马云为阿里巴巴集团主要创始人，于2013年5月出任董事局主席。自1999年集团成立以来来来来来来来来来来来来来来来来来来来来来来来来
              </div>
              <div className="team_btn">了解详情</div>
            </div>
            <div
              className="team_box_cord"
              style={{ border: "1px solid #ccc", marginBottom: "24px" }}
            >
              <div className="team_box_cord_img">图片</div>
              <div className="team_box1">
                <span className="team_cord_name">马云</span>
                <span className="team_cord_zhi">董事局主席</span>
              </div>
              <div className="team_cord_content">
                马云为阿里巴巴集团主要创始人，于2013年5月出任董事局主席。自1999年集团成立以来来来来来来来来来来来来来来来来来来来来来来来来
              </div>
              <div className="team_btn">了解详情</div>
            </div>
            <div
              className="team_box_cord"
              style={{ border: "1px solid #ccc", marginBottom: "24px" }}
            >
              <div className="team_box_cord_img">图片</div>
              <div className="team_box1">
                <span className="team_cord_name">马云</span>
                <span className="team_cord_zhi">董事局主席</span>
              </div>
              <div className="team_cord_content">
                马云为阿里巴巴集团主要创始人，于2013年5月出任董事局主席。自1999年集团成立以来来来来来来来来来来来来来来来来来来来来来来来来
              </div>
              <div className="team_btn">了解详情</div>
            </div>
            <div
              className="team_box_cord"
              style={{ border: "1px solid #ccc", marginBottom: "24px" }}
            >
              <div className="team_box_cord_img">图片</div>
              <div className="team_box1">
                <span className="team_cord_name">马云</span>
                <span className="team_cord_zhi">董事局主席</span>
              </div>
              <div className="team_cord_content">
                马云为阿里巴巴集团主要创始人，于2013年5月出任董事局主席。自1999年集团成立以来来来来来来来来来来来来来来来来来来来来来来来来
              </div>
              <div className="team_btn">了解详情</div>
            </div>
          </div>
        </div>*/}
      </div>
    </>
  );
}
export default ProjectDynamics;
