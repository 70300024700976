import { Carousel } from "antd";
import React from "react";
import "./headerscroll.css";
import { useLocation } from "react-router-dom";
const contentStyle = {
  height: "330px",
  color: "#fff",
  lineHeight: "330px",
  textAlign: "center",
  background: "#364d79",
};
function HeaderScroll(props) {
  const location = useLocation();
  const { data_h1, data_h2 } = props;
  return (
    <>
      {location.pathname == "/tservice" ? (
        <div className="scroll_pub">
          <Carousel autoplay>
            <div className="sroll_img">
              <img
                src="https://static.ecipc.cn/3.28%E6%BA%AF%E6%BA%90%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.jpg"
                alt=""
              />
              <div className="scroll_wenzi">
                <div>全程监管追溯源头</div>
              </div>
            </div>
          </Carousel>
          <div className="com_cord">
            <div className="com_cord_h1">{data_h1}</div>
            <div className="com_cord_h2">{data_h2}</div>
          </div>
        </div>
      ) : location.pathname == "/financial" ? (
        <div className="scroll_pub">
          <Carousel autoplay>
            <div className="sroll_img">
              <img
                src="https://static.ecipc.cn/3.28%E9%87%91%E8%9E%8D%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.jpg"
                alt=""
              />
              <div className="scroll_wenzi">
                <div>金融保障助力发展</div>
              </div>
            </div>
          </Carousel>
          <div className="com_cord">
            <div className="com_cord_h1">{data_h1}</div>
            <div className="com_cord_h2">{data_h2}</div>
          </div>
        </div>
      ) : location.pathname == "/aboutus" ? (
        <div className="scroll_pub">
          <Carousel autoplay>
            <div className="sroll_img">
              <img
                src="https://static.ecipc.cn/3.28%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%95%B0%E5%AD%97%E5%95%86%E5%9F%8E.jpg"
                alt=""
              />
              <div className="scroll_wenzi">
                <div>正品·精品·地标好物</div>
                <div>海丝（厦门）电子商务知识产权运营中心有限公司</div>
              </div>
            </div>
          </Carousel>
          <div className="com_cord">
            <div className="com_cord_h1">{data_h1}</div>
            <div className="com_cord_h2">{data_h2}</div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default HeaderScroll;
