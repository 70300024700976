import { Layout} from 'antd';
import React from 'react';
import './App.css'
import {PhoneOutlined} from '@ant-design/icons';
import {Route,withRouter} from 'react-router-dom'

import HeaderDemo1 from './components/header/headerdemo1/headerdemo1';
// import HeaderDemo2 from './components/header/headerdemo2/headerdemo2';

import Home from './pages/home/home' //首页

import PublicCord from './components/PublicCord/index.jsx'

import Tservice from './pages/Tservice';  //服务大厅
import BigData from './pages/BigData';
// import FinancialService from './pages/financialservice';
import Financial from './pages/financial';//金融
const { Header, Content, Footer } = Layout;



function App(props) {
  const {location} = props
   const path = location.pathname;
  return (
    <Layout>
      <Header>
           <HeaderDemo1/>
      </Header>
      <Content className=''>
         {/* 首页 */}
         <Route path="/" exact component={Home}/>
         <Route path="/home" component={Home}/>
         <Route path="/financial" component={Financial} />
         <Route path="/tservice" component={Tservice} />
        
        
          <Route path="/aboutus" component={PublicCord}/>
         {/* <Route path="/productservice" exact component={ProductService}/>
          <Route path="/bigdata" component={BigData} />
         <Route path="/productservice/detail" component={Detail}/>

         <Route path="/enterprise" component={Enterprise}/>

         <Route path="/team" exact component={Team}/>
         <Route path="/team/teamdetail" component={TeamDetail}/>

         <Route path="/publicity" component={Publicity}/>

         <Route path="/newsInformation" exact component={NewsInformation}/>
         <Route path="/newsInformation/NewsDetail" component={NewsDetail}/>
          
         <Route path="/joinus" exact component={Joinus}/>
         <Route path="/joinus/morejob" component={MoreJob}/>
         <Route path="/joinus/jobdetail" component={JobDetail}/> */}

         
      </Content>
      <Footer className='footer'>
          <div className='main_bottom'>
          2022 <a className='bottom_a' href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">闽ICP备2022012295号-1</a> 海丝（厦门）电子商务知识产权运营中心有限公司

          </div>
      </Footer>
    </Layout>
  );
}

export default withRouter(App);
