import { Link, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import React from "react";

const BreadcrumbNameMap = {
  "/": "首页",
  "/home": "首页",
  "/productservice": "产品与服务",
  "/enterprise": "企业理念",
  "/productservice/detail": "详情介绍",
  "/team": "精英团队",
  "/team/teamdetail": "详情介绍",
  "/publicity": "宣传视频",
  "/newsInformation": "新闻动态",
  "/newsInformation/NewsDetail": "新闻详情",
  "/joinus": "加入我们",
  "/joinus/morejob": "全部职位",
  "/joinus/jobdetail": "职位详情",
  "/aboutus": "关于我们",
  "/financial": "金融服务",
  "/tservice": "溯源服务",
};
const Crumbs = withRouter((props) => {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}></Link>
        <Link to={url}>{BreadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">首页</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <div className="demo" style={{ width: "1200px", margin: "0 auto" }}>
      <Breadcrumb style={{ margin: "16px 0" }}>{breadcrumbItems}</Breadcrumb>
    </div>
  );
});

export default Crumbs;
