import { Carousel, Radio } from "antd";
import { useState } from "react";
import "./index.less";
const contentStyle_1 = {
  height: "75px",
  color: "#999",
  lineHeight: "75px",
  textAlign: "left",
  background: "#fff",
  paddingLeft: "50px",
  marginBottom: "0px",
};
const LittleScroll = ({ getnum }) => {
  const [dotPosition, setDotPosition] = useState("right");
  const handlePositionChange = ({ target: { value } }) => {
    setDotPosition(value);
  };
  const afterChange = (current) => {
    getnum(current);
  };
  return (
    <>
      <div className="center_scroll">
        <div className="">
          <Carousel
            afterChange={(current) => {
              afterChange(current);
            }}
            style={{ height: "75px" }}
            autoplay
            arrows={true}
            dotPosition={dotPosition}
          >
            <div>
              <h3 style={contentStyle_1}>
                推动创新成果流通转化，实现知识产权价值最大化。
              </h3>
            </div>
            <div>
              <h3 style={contentStyle_1}>
                打造知识产权平台，助力地方经济发展。
              </h3>
            </div>
            <div>
              <h3 style={contentStyle_1}>
                建立知识产权要素运营服务，助力知识产权强国战略。
              </h3>
            </div>
            <div>
              <h3 style={contentStyle_1}>
                区块链技术追溯产品从种植到生产销售全链路，确保百分百正品。
              </h3>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default LittleScroll;
