import PublicTitle from "../../../../components/PublicTitle";
import './index.css'
function OurCust() {
  return (
    <>
      <div className="huoban">
        <div className="oc">
          <PublicTitle title1="合作伙伴" title2="Our Customers" />
          <div className="oc_main">
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yh_%E4%B8%AD%E5%9B%BD%E9%93%B6%E8%A1%8C.jpg"
                  alt=""
                />
              </div>
              {/*   */}
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yh_%E5%85%B4%E4%B8%9A%E9%93%B6%E8%A1%8C.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yh_%E5%86%9C%E4%B8%9A%E9%93%B6%E8%A1%8C.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yh_%E5%BB%BA%E8%AE%BE%E9%93%B6%E8%A1%8C.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yh_%E5%8C%97%E4%BA%AC%E9%93%B6%E8%A1%8C.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yl_%E6%96%B0%E6%B5%AA.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yl_%E5%B0%8F%E7%BA%A2%E4%B9%A6.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yl_%E5%BE%AE%E4%BF%A1.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yl_%E4%BB%8A%E6%97%A5%E5%A4%B4%E6%9D%A1.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src="https://static.ecipc.cn/3.28_yl_%E6%8A%96%E9%9F%B3.jpg"
                  alt=""
                />
              </div>
            </div>
            {/* <div className="oc_cord_item">
              <div className="oc_item_img">
                <img
                  src={require("../../../../access/images/厦门理工.jpg")}
                  alt=""
                />
              </div>
               
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OurCust;
