import styles from "./index.module.css";
import HeaderScroll from "../headerScroll/headerscroll";
import Crumbs from "../crumbs/crumbs";
import ComponentBanner from "../../pages/publicity/componentbanner/componentbanner";
// const arr = [
//   "首页",
//   "知识产权服务大厅",
//   "大数据平台",
//   "知识产权数字商城",
//   "溯源服务",
//   "金融服务",
//   "关于我们",
// ];
function PublicCord() {
  return (
    <>
      <HeaderScroll data_h1="关于我们" data_h2="About Us" />
      <Crumbs />
      <div className={styles.pub_main}>
        <div className={styles.pub_left}>
          <ComponentBanner />
        </div>
        <div className={styles.pub_right}>
          <div className={styles.title}>简介</div>
          <div className={styles.di}>
            <p style={{ textIndent: "2em" }}>
            海丝（厦门）电子商务知识产权运营中心有限公司是国家知识产权局批复电子商务知识产权运营中心试点首期项目知识产权数字商城的实施单位。公司成立于2022年7月，现于厦门自贸区知识产权CBD设立1200平现代化办公运营中心，总投资超过5000万元。
       作为福建省重点项目及厦门市政府督办项目，在厦门市市场监督管理局（知识产权局）的指导下，成功构建“一体两翼”解决方案，打造公平公正的电商交易环境，为知识产权强国战略提供强有力支撑。

            </p>
          </div>
          <div>
            <img
              style={{width:"980px"}}
              src="https://static.ecipc.cn/4.7mhzc.jpg"
              alt=""
            />
          </div>
          <div className={styles.di}>
            <p style={{ textIndent: "2em" }}>
              知识产权数字商城主要承担国家知识产权运营公共服务平台在电子商务领域创新试点平台建设、知识产权强企建设以及高价值知识产权培育运营等重任，按照政府引导、国际标准、高点定位、市场化运作，推动创新成果流通转化，实现知识产权价值
              最大化。以优质知识产权为基本核心，以优质正品精品为载体，解决“假冒伪劣多、维权打假难、收税难、商品价格偏离价值并严重冲击实体经济”四大痛点，促成双方交易，打造成为百分之百正品电商平台。
            </p>
            <br />
            <p style={{ textIndent: "2em"}}>
              建立知识产权要素运营服务，平台建立知识产权大数据分析平台，依托“臻品码”体系完成产品全链路数据采集分析，提供认证管理应用及一物一码可监测、数据可应用（统计测算知识产权产值贡献，凸显知识产权价值）等功能。专利、商标、地理标志、商业秘密等知识产权要素全链条运营。
              运营中心目前已经组建以专家为主的运营团队，完成顶层设计、运营策略、数据运营、技术开发等工作，2022年10月1日上线内测版，2023年1月1日上线正式运营版，实现中国知识产权发展的“新运营新零售模式”。
            </p>
          </div>
          <div className={styles.title}>优势</div>
          <div className={styles.di}>
            <p style={{ textIndent: "2em" }}>
              运营模式优势：知识产权是国家战略的重要组成部分，运营中心经国家知识产权局批复成立，在厦门市市场监督管理局（知识产权局）指导下、厦门市商标（品牌）协会支持下，开展知识产权领域相关工作；携手字节跳动、阿里巴巴、小红书等互联网企业，打通公域私域流量；与中国银行、中国建设银行、中国工商银行等达成战略合作，为企业提供专业金融服务。整合知识产权数据库，从知识产权认证保护、溯源管理、品牌运营到销售通路全链路打通，助力地方经济发展。
            </p>
            <p style={{ textIndent: "2em" }}>
              技术领先优势：通过打通政企数据，打造提供决策建议与形势预判的大数据平台。Web3.0领军者，有效解决Web2.0存在的垄断、隐私保护、算法作恶等问题，通过区块链技术追溯产品从种植到生产销售全链路，确保百分百正品。利用元宇宙技术，打造沉浸式购物体验。
            </p>
          </div>
          <div className={styles.title}>发展历程</div>
          <div className={styles.di}>
            2021年12月29日 国家知识产权局批复成立
            <br />
            2022年4月8日
            厦门市场监督管理局（知识产权局）与市商标（品牌）协会在我司召开ECIPC项目推介会
            <br />
            2022年5月10日
            与市场监督管理局（知识产权局）现场会上确立项目独立、加速推进
            <br />
            2022年7月1日
            向市场监督管理局（知识产权局）进行初创阶段性汇报及原型演示，获得局方领导高度认可
            <br />
            2022年9月9日
            中国（厦门）电子商务知识产权运营中心暨知识产权数字商城全国推介会，在第二十二届中国国际投资贸易洽谈会签约大厅隆重举办
            <br />
            2022年10月1日
            知识产权数字商城网页端及小程序端开发、封测完成，进入内测
            <br />
            2023年1月1日 知识产权数字商城正式上线运营
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicCord;
