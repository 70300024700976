import HeaderScroll from "../../components/headerScroll/headerscroll";
import Crumbs from "../../components/crumbs/crumbs";
import ComponentBanner from "../publicity/componentbanner/componentbanner";
import styles from  "./index.module.css";
function Financial() {
  return (
    <>
      <HeaderScroll data_h1="金融服务" data_h2="Financial Service" />
      <Crumbs />
      <div className={styles.pub_main}>
        <div className={styles.pub_left}>
          <ComponentBanner />
        </div>
        <div className={styles.pub_right}>
          <div className={styles.title}>金融服务</div>
          <div className={styles.bi}>
            与各大银行达成战略合作，为企业提供专业金融服务，解决资金问题。
          </div>
          <div>
            <img
              style={{width:"100%" }}
              src="https://static.ecipc.cn/3.18_%E9%87%91%E8%9E%8D.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Financial;
