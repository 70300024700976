import "./index.less";
import { Carousel } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import LittleScroll from "../LitterScroll/index";
import React, { useRef, useState } from "react";

const contentStyle = {
  height: "600px",
  color: "#fff",
  lineHeight: "600px",
  textAlign: "center",
  background: "#364d79",
};

const HomeHeaderScroll = () => {
  const ref = useRef();
  const [num, setnum] = useState(0);
  console.log(ref);

  const leftbtn = () => {
    ref.current.next();
  };
  const rightbtn = () => {
    ref.current.prev();
  };
  const getcurrentchild = (v) => {
    // ref.current.goTo(num);
    setnum(v);
  };
  return (
    <>
      <div className="nav">
        <div
          onClick={() => {
            leftbtn();
          }}
          className="leftbtn btn"
        >
          <LeftCircleOutlined />
        </div>
        <div
          className="rightbtn btn"
          onClick={() => {
            rightbtn();
          }}
        >
          <RightCircleOutlined />
        </div>
        <Carousel
          arrows={true}
          dots={false}
          style={{ position: "relative" }}
          autoplay
          ref={ref}
        >
          <div className="sroll_img">
            <img
              src="https://static.ecipc.cn/3.28%E5%A4%A7%E6%95%B0%E6%8D%AE%E5%B9%B3%E5%8F%B0.jpg"
              alt=""
            />
            <div className="scroll_wenzi">
              <div>数智商机 数谋未来</div>
              <div>大数据赋能企业发展</div>
            </div>
          </div>
          <div className="sroll_img">
            <img
              src="https://static.ecipc.cn/3.28%E9%87%91%E8%9E%8D%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.jpg"
              alt=""
            />
            <div className="scroll_wenzi">
              <div>金融保障助力发展</div>
              {/* <div>了解提升应用可用性 了解更多</div> */}
            </div>
          </div>
          <div className="sroll_img">
            <img
              src="https://static.ecipc.cn/3.28%E6%BA%AF%E6%BA%90%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0.jpg"
              alt=""
            />
            <div className="scroll_wenzi">
              <div>全程监管追溯源头</div>
              {/* <div>阿里云PAI或权威机构Forrester高度肯定</div> */}
            </div>
          </div>
          <div className="sroll_img">
            <img
              src="https://static.ecipc.cn/3.28%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%9C%8D%E5%8A%A1%E5%A4%A7%E5%8E%85.jpg"
              alt=""
            />
            <div className="scroll_wenzi">
              <div>运用知识产权战略 </div>
              <div style={{ fontSize: "70px", fontWeight: "600" }}>
                助力全球商业竞争
              </div>
            </div>
          </div>
          <div className="sroll_img">
            <img
              src="https://static.ecipc.cn/3.28%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%95%B0%E5%AD%97%E5%95%86%E5%9F%8E.jpg"
              alt=""
            />
            <div className="scroll_wenzi">
              <div>正品·精品·地标好物</div>
              <div>海丝（厦门）电子商务知识产权运营中心有限公司</div>
            </div>
          </div>
        </Carousel>
      </div>
      <LittleScroll getnum={getcurrentchild} />
    </>
  );
};

export default HomeHeaderScroll;
