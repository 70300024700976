import HeaderScroll from "../../components/headerScroll/headerscroll";
import Crumbs from "../../components/crumbs/crumbs";
import ComponentBanner from "../publicity/componentbanner/componentbanner";
function BigData() {
  return (
    <>
      <HeaderScroll data_h1="大数据平台" data_h2="Big Data" />
      <Crumbs />
      <div className="pub_main">
        <div className="pub_left">
          <ComponentBanner />
        </div>
        <div className="pub_right p_right">
          <div className="p_box1">大数据平台</div>
          <div className="p_box2">
            通过打通政企数据，打造可提供决策建议与形势预判的大数据平台服务。
          </div>
          <div>
            <img
              className="img22"
              src="https://qn.cncsit.com/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BigData;
