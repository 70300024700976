import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./headerdemo1.css";
import { PhoneOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";

function HeaderDemo1() {
  const location = useLocation();
  const history = useHistory();
  const [current, setcurrent] = useState(9);
  return (
    <>
      <div className="header1">
        <div className="header01_box" style={{width:'1200px'}}>
          <div className="header01_box_left">
            <img style={{height:'30px'}} src="https://static.ecipc.cn/2023_7_4mhicon.png" alt="" />
          </div>
          <div className="header01_box_center">
            <ul>
              <Link to="/home">
                <li className={location.pathname == "/home" ? "active" : ""}>
                  首页
                </li>
              </Link>
              <a href="https://mall.ecipc.cn/" target="_blank" rel="noreferrer">
                <li>知识产权数字商城</li>
              </a>
              <a href="https://data.ecipc.cn/" target="_blank" rel="noreferrer">
                <li>大数据平台</li>
              </a>
              <Link to="/tservice">
                <li
                  className={location.pathname == "/tservice" ? "active" : ""}
                >
                  溯源服务
                </li>
              </Link>
              {/* https://www.xmipop.com/ */}
              <a
                href="https://mall.ecipc.cn/geograph_pc"
                target="_blank"
                rel="noreferrer"
              >
                <li>地理标志查询</li>
              </a>
              <Link to="/financial">
                <li
                  className={location.pathname == "/financial" ? "active" : ""}
                >
                  金融服务
                </li>
              </Link>
              <Link to="/aboutus">
                <li className={location.pathname == "/aboutus" ? "active" : ""}>
                  关于我们
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderDemo1;
