import "./index.css";
import { useHistory, Link } from "react-router-dom";
function HomeBottom() {
  const history = useHistory();
  return (
    <>
      <div className="homebottom">
        <div className="homebottom_box">
          <div className="bot_item">
            <h2>关于我们</h2>
            <div
              onClick={() => {
                history.push("/publiccord");
              }}
            >
              中心介绍
            </div>
            <div
              onClick={() => {
                history.push("/publiccord");
              }}
            >
              中心优势
            </div>
            <div
              onClick={() => {
                history.push("/publiccord");
              }}
            >
              发展历程
            </div>
            <div
              onClick={() => {
                history.push("/publiccord");
              }}
            >
              合作伙伴
            </div>
          </div>
          <div className="bot_item">
            <h2>我们的服务</h2>
            <div>
              <a href="https://www.xmipop.com/" target="_blank">
                知识产权服务
              </a>
            </div>
            <div>
              <a href="https://data.ecipc.cn/home" target="_blank">
                大数据服务
              </a>
            </div>
            <div>
              <a href="https://mall.ecipc.cn/cloud" target="_blank">
                知识产权数字商城
              </a>
            </div>
            <div
              onClick={() => {
                history.push("/tservice");
              }}
            >
              溯源服务
            </div>
            <div
              onClick={() => {
                history.push("/financial");
              }}
            >
              金融服务
            </div>
          </div>
          <div className="bot_item">
            <h2>加入我们</h2>
            <div>联系我们</div>
          </div>
          <div className="bot_item">
            <h2>关注我们</h2>
            <div>
              <img src="https://static.ecipc.cn/mg_qrcode_23_7_4.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBottom;
