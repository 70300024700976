import styles from "./index.module.css";
import HeaderScroll from "../../components/headerScroll/headerscroll";
import Crumbs from "../../components/crumbs/crumbs";
import ComponentBanner from "../publicity/componentbanner/componentbanner";

function Tservice() {
  return (
    <>
      <HeaderScroll data_h1="溯源服务" data_h2="Traceability Service" />
      <Crumbs />
      <div className={styles.pub_main}>
        <div className={styles.pub_left}>
          <ComponentBanner />
        </div>
        <div className={styles.pub_right}>
          <div className={styles.title}>溯源服务</div>
          <div className={styles.bi}>
            依托“臻品码”体系完成产品全链路数据采集分析，提供认证管理应用及一物一码可监测、数据可应用（统计测算知识产权产值贡献，凸显知识产权价值）等功能
          </div>
          <div>
            <img
              style={{width:'100%' }}
              src="https://static.ecipc.cn/3.18_zhen.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Tservice;
