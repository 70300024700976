import "../../home.css";
import "./index.css";
function BrandStory() {
  return (
    <>
      <div className="more_time_box">
        {/* <div className="more_box_head">
          <div className="more_little_box">
            <div className="more_year">1999</div>
            <span>年</span>
            <div className="more_gs">公司成立时间</div>
          </div>
          <div className="more_little_box">
            <div className="more_year">1999</div>
            <span>年</span>
            <div className="more_gs">公司成立时间</div>
          </div>
          <div className="more_little_box">
            <div className="more_year">1999</div>
            <span>年</span>
            <div className="more_gs">公司成立时间</div>
          </div>
          <div className="more_little_box">
            <div className="more_year">1999</div>
            <span>年</span>
            <div className="more_gs">公司成立时间</div>
          </div>
          <div className="more_little_box" style={{ borderRight: "0" }}>
            <div className="more_year">1999</div>
            <span>年</span>
            <div className="more_gs">公司成立时间</div>
          </div>
        </div> */}
      </div>
      <div className="pinpai">
        <div className="about_us_box">
          <div className="aboutus_left" style={{height:"380px",lineHeight:"380px"}}>
            <img style={{height:'300px'}} src="https://static.ecipc.cn/3.28_3_02.png" alt="" />
          </div>
          <div className="aboutus_right">
            <div className="aboutus_right_title">
              <div className="story">品牌故事</div>
              <div className="titlestory"> About US</div>
            </div>
            <div className="aboutus_right_content">
              <p className="s_p">
              海丝（厦门）电子商务知识产权运营中心有限公司是国家知识产权局批复电子商务知识产权运营中心试点首期项目知识产权数字商城的实施单位。公司成立于2022年7月，现于厦门自贸区知识产权CBD设立1200平现代化办公运营中心，总投资超过5000万元。
       作为福建省重点项目及厦门市政府督办项目，在厦门市市场监督管理局（知识产权局）的指导下，成功构建“一体两翼”解决方案，打造公平公正的电商交易环境，为知识产权强国战略提供强有力支撑。

              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandStory;
