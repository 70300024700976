import { Carousel } from "antd";
import React, { useState } from "react";
import "./home.css";
// import img from "../../access/images/home_map.jpg";

// import Knowledge from "./components/Knowledge/index";
import ProjectDynamics from "./components/ProjectDynamics";
// import Partner from "./components/Partner";
import News from "./components/News";
import HomeBottom from "./components/HomeBottom";
import Product from "./components/Produce";

import BrandStory from "./components/BrandStory";
import VideoModule from "./components/VideoModule";
// import JoinUs from "./components/JoinUs";
import OurCust from "./components/OurCust";
import HomeHeaderScroll from "./components/HomeHeaderScroll";
const contentStyle= {
  height: "600px",
  color: "#fff",
  lineHeight: "600px",
  textAlign: "center",
  background: "#364d79",
};

function Home() {
  const [dotPosition, setDotPosition] = useState("right");
  const handlePositionChange = ({ target: { value } }) => {
    setDotPosition(value);
  };
  return (
    <>
      <HomeHeaderScroll />

      <Product />
      <News />
      <BrandStory />
      <VideoModule />
      <ProjectDynamics />
      {/* <JoinUs /> */}
      <OurCust />
      <HomeBottom />
    </>
  );
}

export default Home;
